const apiKey = 'B6126CF2-F4A0-4432-929A-BB84DDC9DF82'; // Your BEA API key

async function fetchGdpData() {
    const url = 'https://apps.bea.gov/api/data/';

    const params = {
        UserID: apiKey,
        method: 'GetData',
        DataSetName: 'GDPbyIndustry',
        Year: '2023',
        Industry: 'ALL',
        TableID: '1',
        Frequency: 'A',
        ResultFormat: 'json',
    };

    try {
        const response = await fetch(url + '?' + new URLSearchParams(params));
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }
        const data = await response.json();
        const resultingArray = await data.BEAAPI.Results[0].Data;
        resultingArray.sort((a, b) => b.DataValue - a.DataValue);
        const total_gdp = resultingArray[0].DataValue;
        const topFive = resultingArray.slice(3, 10)
            .filter((_, index) => index !== 2 && index !== 4) // Skip the fifth and the third one
            .map(industry => ({
                IndustryDescription: industry.IndustrYDescription.replace(/<sup>.*?<\/sup>/g, ''),
                DataValue: industry.DataValue,
                PercentageOfGDP: `${((industry.DataValue / total_gdp) * 100).toFixed(2)}%`
            }));

        return topFive;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export default fetchGdpData
