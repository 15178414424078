export default function numberToLetter(value) {
    /**
     * Converts a number into a letter-based format based on its magnitude.
     * Examples:
     *   10,000 -> "10K"
     *   12,345,678 -> "12.3M"
     *   1,234,567,890 -> "1.2B"
     *   12,345,678,900,000 -> "12.3T"
     * @param {number} value - The number to convert.
     * @returns {string} - The formatted string with the appropriate letter.
     */
    if (typeof value !== 'number' || isNaN(value)) {
        return "Invalid input";
    }

    if (value < 1_000) {
        return value.toString();
    } else if (value < 1_000_000) {
        return (value / 1_000).toFixed(value % 1_000 === 0 ? 0 : 2) + "K";
    } else if (value < 1_000_000_000) {
        return (value / 1_000_000).toFixed(value % 1_000_000 === 0 ? 0 : 3) + "M";
    } else if (value < 1_000_000_000_000) {
        return (value / 1_000_000_000).toFixed(value % 1_000_000_000 === 0 ? 0 : 3) + "B";
    } else {
        return (value / 1_000_000_000_000).toFixed(value % 1_000_000_000_000 === 0 ? 0 : 3) + "T";
    }
}