export const fetchStockData = async (symbol) => {
    try {
      const response = await fetch(`https://api.finalyst.xyz/stock-data/${symbol}`);
      const data = await response.json();
      console.log('Stock data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching stock data:', error);
      throw error;
    }
  };
  