import React, { useState } from 'react';
import { BusinessProfile, ProductsServices, Risks, Holders, Officers, Filings } from '../CompanyData/CompanyData';
import FinancialStatementsTab from './FinancialStatementsTab';

function CompanyTabs({ symbol, summary, risks, holders, officers, financialData, filings }) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabClick = (index) => {
        setSelectedTab(index);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 0:
                return <BusinessProfile symbol={symbol} summary={summary} />;
            case 1:
                return <ProductsServices symbol={symbol} />;
            case 2:
                return <Risks symbol={symbol} risks={risks} />;
            case 3:
                return <FinancialStatementsTab financialData={financialData}/>;
            case 4:
                return <Holders symbol={symbol} holders={holders} />;
            case 5:
                return <Officers symbol={symbol} officers={officers}/>;
            case 6:
                return <Filings filings={filings} />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className='tabs-container'>
                <div className={`tab ${selectedTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>Business Profile</div>
                <div className={`tab ${selectedTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>Products & Services</div>
                <div className={`tab ${selectedTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>Risks</div>
                <div className={`tab ${selectedTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3)}>Financial Statements</div>
                <div className={`tab ${selectedTab === 4 ? 'active' : ''}`} onClick={() => handleTabClick(4)}>Holders</div>
                <div className={`tab ${selectedTab === 5 ? 'active' : ''}`} onClick={() => handleTabClick(5)}>Officers</div>
                <div className={`tab ${selectedTab === 6 ? 'active' : ''}`} onClick={() => handleTabClick(6)}>Filings</div>
            </div>
            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
}

export default CompanyTabs;
