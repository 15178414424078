import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import { SidebarData } from './SidebarData';
import { LogoutOutlined } from '@mui/icons-material';

function Sidebar({ onSignOut, userEmail }) {
  const [favorites, setFavorites] = useState([]);
  const [favoritesOpen, setFavoritesOpen] = useState(false);

  // Replace this with the actual user's email, perhaps from props or context
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem(`favorites_${userEmail}`)) || [];
    setFavorites(storedFavorites);
  }, [userEmail]);

  const handleSignOut = (e) => {
    e.preventDefault();
    if (onSignOut && typeof onSignOut === 'function') {
      onSignOut();
    }
  };

  const handleFavoritesClick = (e) => {
    e.preventDefault();
    setFavoritesOpen((prev) => !prev);
  };

  return (
    <div className='sidebar'>
      <img className="logo" src="../assets/finalystLogo/Finalyst-logo-250x56.png" alt="Finalyst Logo" />
      <ul className='list'>
        {SidebarData.map((val, key) => {
          // Handle Favorites separately
          if (val.title === 'Favorites') {
            return (
              <li key={key} className='row'>
                <button className='row-link' onClick={handleFavoritesClick}>
                  <div id='icon'>{val.icon}</div>
                  <div id='title'>{val.title}</div>
                </button>
                {favoritesOpen && (
                  <ul className="favorites-dropdown">
                    {favorites.length > 0 ? (
                      favorites.map((favorite, index) => (
                        <li key={index}><NavLink id='row-link' to={`/company/${favorite}`}>{favorite}</NavLink></li>
                      ))
                    ) : (
                      <li>No favorites available</li>
                    )}
                  </ul>
                )}
              </li>
            );
          } 
          // Handle Recent similarly if needed:
          else if (val.title === 'Recent') {
            return (
              <li key={key} className='row'>
                <button className='row-link' onClick={(e) => e.preventDefault()}>
                  <div id='icon'>{val.icon}</div>
                  <div id='title'>{val.title}</div>
                </button>
                {/* Add dropdown logic for Recent if desired */}
              </li>
            );
          } 
          // Default case for items that do navigate
          else {
            return (
              <li key={key} className='row' id='row-link-sidebar'>
                <NavLink 
                  to={val.path} 
                  className='row-link'
                >
                  <div id='icon'>{val.icon}</div>
                  <div id='title'>{val.title}</div>
                </NavLink>
              </li>
            );
          }
        })}
      </ul>
      
      <div className="sign-out-container">
        <button onClick={handleSignOut} className="sign-out-button">
          <div id='icon'><LogoutOutlined /></div>
          <div id='title'>Sign Out</div>
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
