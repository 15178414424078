import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchStockData } from '../../../api';
import numberToLetter from '../../../utils/rounding';
import './FavoritesTable.css';

function FavoritesTable({ userEmail }) {
  // Initialize favoritesArray from localStorage only once
  const [favoritesArray, setFavoritesArray] = useState(() => {
    if (!userEmail) return [];
    const favoritesKey = `favorites_${userEmail}`;
    return JSON.parse(localStorage.getItem(favoritesKey)) || [];
  });

  // Store fetched favorites data
  const [favoritesData, setFavoritesData] = useState([]);

  // Persist favoritesArray to localStorage whenever it or userEmail changes
  useEffect(() => {
    if (!userEmail) return;
    const favoritesKey = `favorites_${userEmail}`;
    localStorage.setItem(favoritesKey, JSON.stringify(favoritesArray));
  }, [favoritesArray, userEmail]);

  // Function to remove a favorite
  const removeFavorite = (symbol) => {
    setFavoritesArray(prevFavorites => 
      prevFavorites.filter(favorite => favorite !== symbol)
    );
  };

  // Fetch data based on favoritesArray changes
  useEffect(() => {
    if (!userEmail) return;

    const fetchMarketData = async () => {
      try {
        if (favoritesArray.length > 0) {
          // Fetch all stock data in parallel
          const stockMarketData = await Promise.all(
            favoritesArray.map((symbolItem) => fetchStockData(symbolItem))
          );

          // Calculate percentage change for each symbol
          const changeInPrice = stockMarketData.map((item, index) => {
            try {
              if (
                !item ||
                !item.priceInfo ||
                !Array.isArray(item.priceInfo) ||
                !item.priceInfo[0]?.closing_prices
              ) {
                console.warn(`Missing or invalid data for index ${index}`, item);
                return 'N/A';
              }

              const closingPrices = item.priceInfo[0].closing_prices;
              if (!Array.isArray(closingPrices) || closingPrices.length < 2) {
                console.warn(
                  `Not enough data to calculate price change for index ${index}`,
                  closingPrices
                );
                return 'N/A';
              }

              const lastClosingPrice = closingPrices[closingPrices.length - 1];
              const previousClosingPrice = closingPrices[closingPrices.length - 2];

              if (
                typeof lastClosingPrice !== 'number' ||
                typeof previousClosingPrice !== 'number'
              ) {
                console.warn(
                  `Invalid price data types at index ${index}`,
                  lastClosingPrice,
                  previousClosingPrice
                );
                return 'N/A';
              }

              const change = lastClosingPrice - previousClosingPrice;
              return `${((change / previousClosingPrice) * 100).toFixed(1)}%`;
            } catch (error) {
              console.error(`Error processing item at index ${index}`, item, error);
              return 'Error';
            }
          });

          // Build an array of objects for the table
          const updatedFavoritesData = favoritesArray.map((symbol, index) => ({
            company: symbol,
            marketCap: stockMarketData[index]?.marketCap || 'N/A',
            change: changeInPrice[index] || '0%',
          }));

          setFavoritesData(updatedFavoritesData);
        } else {
          // If there are no favorites, clear the table
          setFavoritesData([]);
        }
      } catch (error) {
        console.error('Error fetching market data:', error);
      }
    };

    fetchMarketData();
  }, [favoritesArray, userEmail]);

  return (
    <div className="favorites-section">
      <h3 style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px', fontSize: '24px', fontWeight: 'bold'}}>Favorites</h3>
      <table className="favorites-table">
        <thead>
          <tr>
            <th>Company</th>
            <th>Market Cap</th>
            <th>Change</th>
            <th style={{color: 'gray'}}>Remove</th>
          </tr>
        </thead>
        <tbody>
          {favoritesData.length === 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center', fontSize: '24px', color: 'gray', padding: '40px' }}>
                Here will appear the companies that you mark as favorites
              </td>
            </tr>
          ) : (
            favoritesData.map((favorite, index) => (
              <tr className="favorite-row" key={index}>
                <td id="companyName">
                  {favorite.company ? (
                    <Link
                      style={{ textDecoration: 'none', color: 'black' }}
                      to={`/company/${favorite.company}`}
                    >
                      {favorite.company}
                    </Link>
                  ) : (
                    favorite.company
                  )}
                </td>
                <td>{numberToLetter(favorite.marketCap)}</td>
                <td>{favorite.change}</td>
                <td>
                  <button
                    style={{
                      backgroundColor: 'white',
                      border: 'none',
                      color: 'gray',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      fontSize: '32px',
                    }}
                    onClick={() => removeFavorite(favorite.company)}
                    className="remove-favorite-btn"
                  >
                    -
                  </button>
                </td>
              </tr>
            ))
          )}

        </tbody>
      </table>
    </div>
  );
};
export default FavoritesTable;