import React from 'react';
import {HomeOutlined, AccessTime, FavoriteBorderOutlined, TimelineOutlined, ArticleOutlined} from '@mui/icons-material';

export const SidebarData = [ 
    {
    title: 'Home',
    icon: <HomeOutlined/>,
    path: '/home',
},
{
    title: 'Recent',
    icon: <AccessTime/>,
    path: '/recent',
},
{
    title: 'Favorites',
    icon: <FavoriteBorderOutlined/>,
    path: '/favorites',
},
{
    title: 'Economical Data',
    icon: <TimelineOutlined/>,
    path: '/economical-data',
},
{
    title: 'Research',
    icon: <ArticleOutlined/>,
    path: '/research',
},


]



