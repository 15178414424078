import React, { useState, useEffect } from 'react';
import {Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import awsconfig from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import { Home, EconomicalData, Company } from './pages';

Amplify.configure(awsconfig);

function AppContent({ signOut, user }) {
  const [userEmail, setUserEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const getCurrentUserEmail = async () => {
      try {
        const { signInDetails } = await getCurrentUser();
        if (isMounted && signInDetails?.loginId) {
          setUserEmail(signInDetails.loginId);
        }
      } catch (err) {
        console.error('Error getting current user:', err);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
  
    getCurrentUserEmail();
  
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array as we only need to fetch once
  
  if (isLoading) {
    return <LoadingSpinner />;
  }

  
  

  // Routes
  return (
    <div className="app-container">
      <Sidebar onSignOut={signOut} userEmail={userEmail} />
      <Navbar user={user} />
      <main className="main-content">
        <Routes>
          <Route path="/" 
          element={<Navigate to="/home" replace />} 
          />
          <Route path="/home" 
          element={<Home userEmail={userEmail} />} 
          />
          <Route path="/economical-data" 
          element={<EconomicalData />} 
          />
          <Route path="/company/:symbol" 
          element={<Company userEmail={userEmail} />} />

          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </main>
    </div>
  );
}

function App() {
  return (

      <Authenticator
        initialState="signIn"
        loginMechanisms={['email']}
        // credentials are given by us, and manually created on AWS Cognito User Pool console
        hideSignUp={true}
        variation="modal"
        components={{
          SignIn: {
            Header() {
              return (
                <div className="text-center">
                  <h1>Welcome to Finalyst</h1>
                </div>
              );
            },
            Footer() {
              return null;
            }
          }
        }}
      >
        {(props) => <AppContent {...props} />}
      </Authenticator>

  );
}

export default App;