import React, { useMemo } from 'react';
import companies from '../../../companies.json';

const EquitiesTable = () => {
  // Memoize so that equitiesData will be the same reference unless `companies` changes
  const equitiesData = useMemo(() => {
    return companies.slice(0, 10).map((company) => ({
      company: company.name,
      symbol: company.symbol,
    }));
  }, []);




  return (
    <div id="scrollableTableContainer">
      <table className="favorites-table">
        <thead>
          <tr>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {equitiesData.map((item, index) => (
            <tr key={index}>
              <td><a style={{ textDecoration: 'none', color: 'black' }} href={`/company/${item.symbol}`}>{item.company}</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EquitiesTable;
