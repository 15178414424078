import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CompanyHeader from '../components/CompanyHeader/CompanyHeader';
import { fetchStockData, fetchRiskFactors, fetchFilings } from '../api';
import { getFinancialData } from '../api';
import { formatCompanyName } from '../utils/companyNameFormat';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import CompanyTabs from '../components/Tabs/CompanyTabs';
import PriceData from '../components/PriceData/PriceData'; // Import PriceData component

function Company({ userEmail }) {
  const { symbol } = useParams();
  const location = useLocation();
  const [companyInfo, setCompanyInfo] = useState(null);
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [riskFactors, setRiskFactors] = useState('');
  const [showPriceData, setShowPriceData] = useState(false); // State to toggle PriceData component
  const [filings, setFilings] = useState([]);
  

  const companyName = location.state?.companyName || companyInfo?.longName || 'Unknown Company';
  const logoSrc = `../assets/equitiesLogos/${formatCompanyName(companyName)}.png`;

  useEffect(() => {

    const fetchData = async () => {
      try {
        const stockData = await fetchStockData(symbol);
        setCompanyInfo(stockData);

        const riskData = await fetchRiskFactors(symbol, userEmail);
        setRiskFactors(riskData.riskFactors);

        await getFinancialData(symbol, userEmail, setLoading, setFinancialData, setError);
        
        const filings_docs = await fetchFilings(symbol);
        setFilings(filings_docs);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [symbol, userEmail]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error: {error.message}</div>;

  const handlePriceDataClick = () => {
    setShowPriceData(!showPriceData); // Toggle PriceData visibility
  };

  return (
    <div>
      {companyInfo && (
        <CompanyHeader 
          companyName={formatCompanyName(companyInfo.longName)}
          ticker={symbol}
          price={companyInfo.price}
          sector={companyInfo.sector}
          industry={companyInfo.industry}
          logo={logoSrc}
          marketCap={companyInfo.marketCap}
          enterpriseValue={companyInfo.enterpriseValue}
          sharesOutstanding={companyInfo.sharesOutstanding}
          employees={companyInfo.employees}
          currency={companyInfo.currency}
          website={companyInfo.website}
          onPriceDataClick={handlePriceDataClick} // Pass the click handler to CompanyHeader
          userEmail={userEmail}
        />
      )}

      {showPriceData && companyInfo.priceInfo && <PriceData priceInfo={companyInfo.priceInfo[0]} ticker={symbol} />}
      

      <CompanyTabs 
        symbol={symbol} 
        summary={companyInfo.longBusinessSummary} 
        risks={riskFactors}
        holders={companyInfo.institutionalHolders}
        officers={companyInfo.officers}
        financialData={financialData}
        filings={filings}
      />
    </div>
  );
}

export default Company;