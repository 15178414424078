import React, { useState, useEffect } from 'react';
import fetchGdpData from './IndustryData.js';
import '../FavoritesTable/FavoritesTable.css';

function IndustryTable() {
  const [topFive, setTopFive] = useState([]);

  // Fetch on mount
  useEffect(() => {
    async function getData() {
      const data = await fetchGdpData();
      setTopFive(data);
    }
    getData();
  }, []);

  return (
    <div className="favorites-section">
      <h3 style={{
        textAlign: 'center',
        marginBottom: '10px',
        marginTop: '10px',
        fontSize: '24px',
        fontWeight: 'bold'
      }}>Industries</h3>

      <table className="favorites-table">
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Industry</th>
            <th>Size</th>
            <th>% of GDP</th>
          </tr>
        </thead>
        <tbody>
          {topFive.map((item, index) => (
            <tr key={index}>
              <td>{item.IndustryDescription}</td>
              <td>{item.DataValue}</td>
              <td>{item.PercentageOfGDP}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default IndustryTable;
