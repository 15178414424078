import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import './GdpOutputChart.css'; // Import a CSS file for styling
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

// Register required components for Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const GdpOutputChart = () => {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true); // To manage loading state
  const apiKey = 'B6126CF2-F4A0-4432-929A-BB84DDC9DF82'; // Your BEA API key

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apps.bea.gov/api/data/', {
          params: {
            UserID: apiKey,
            method: 'GetData',
            DataSetName: 'GDPbyIndustry',
            Year: '2023',
            Industry: 'ALL',
            TableID: '1',
            Frequency: 'A',
            ResultFormat: 'json',
          },
        });

        console.log('Full API Response:', response);

        const data = response.data?.BEAAPI?.Results[0]?.Data;
        
        if (data && Array.isArray(data)) {
          const sortedData = data.map(item => ({
            description: item.IndustrYDescription.replace(/<sup>.*?<\/sup>/g, ''),
            value: parseFloat(item.DataValue),
          })).sort((a, b) => b.value - a.value);

          const top5 = sortedData.slice(3, 8);
          const othersValue = sortedData.slice(5).reduce((acc, item) => acc + item.value, 0);

          const labels = [...top5.map(item => item.description), 'Others'];
          const values = [...top5.map(item => item.value), othersValue];

          setChartData({
            labels: labels,
            datasets: [
              {
                data: values,
                backgroundColor: [
                  '#78E4FF', '#70D4EF', '#68C4DF', '#60B4CF', '#58A4BF', '#5094AF'
                ],
              },
            ],
          });
        } else {
          console.error('Data is not in expected format:', data);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching BEA data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="gdp-chart-container">
      <h3 className="gdp-chart-title">GDP Output by Industry in US</h3>
      <p className="gdp-chart-subtitle">Numbers in billions</p>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Doughnut 
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1.8,
            plugins: {
              legend: {
                display: false,
              },
            },
            cutout: '70%', // Increased cutout to make the doughnut thinner
          }}
        />
      )}
    </div>
  );
};

export default GdpOutputChart;
