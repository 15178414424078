import axios from "axios";

export const getFinancialData = async (symbol, userEmail, setLoading, setFinancialData, setError) => {
    setLoading(true); // Set loading to true when data fetching starts
    try {
        const response = await axios.get(`https://api.finalyst.xyz/financial-data/${symbol}`, {
            params: { user_email: userEmail }
        });
        const data = response.data;
        setFinancialData(data);
        setError(null);
    } catch (error) {
        console.error("Error fetching financial data:", error);
        if (error.response) {
            // Server responded with a status other than 200 range
            const { status, statusText, data } = error.response;
            setError(`Error: ${status} - ${statusText}\n${data}`);
        } else if (error.request) {
            // No response received from server
            setError("No response received from the server.");
        } else {
            // Other errors
            setError(error.message);
        }
    } finally {
        setLoading(false); // Set loading to false once data fetching is complete
    }
};
