import React, { useState } from 'react';
import './PriceData.css';
import TradingViewChart from '../TradingView/TradingViewChart';

function PriceData({ priceInfo, ticker}) {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null; // Hide the component when closed
  }

  return (
    <div className="price-data-container">
      <span className="close-icon" onClick={handleClose}>−</span> {/* Close icon */}
      <div className="price-box-wrapper">
        <div className="price-box">
          <h3>Stock Price History</h3>
          <table>
            <tbody>
              <tr>
                <td>Beta (5Y Monthly)</td>
                <td>{priceInfo.beta || 'N/A'}</td>
              </tr>
              <tr>
                <td>52 Week Range</td>
                <td>{priceInfo.fifty_two_week_change || 'N/A'}</td>
              </tr>
              <tr>
                <td>52 Week High</td>
                <td>{priceInfo.fifty_two_week_high || 'N/A'}</td>
              </tr>
              <tr>
                <td>52 Week Low</td>
                <td>{priceInfo.fifty_two_week_low || 'N/A'}</td>
              </tr>
              <tr>
                <td>200-day Moving Average</td>
                <td>{priceInfo.two_hundred_day_average || 'N/A'}</td>
              </tr>
              <tr>
                <td>Earnings per Share (EPS)</td>
                <td>{priceInfo.earnings_per_share || 'N/A'}</td>
              </tr>
              <tr>
                <td>Price to Earnings Ratio (P/E Trailing)</td>
                <td>{priceInfo.price_earnings_ratio + ' x' || 'N/A'}</td>
              </tr>
              <tr>
                <td>Book Value per Share</td>
                <td>{priceInfo.book_value || 'N/A'}</td>
              </tr>
              <tr>
                <td>Price to Book Ratio (P/B)</td>
                <td>{priceInfo.price_to_book_ratio + ' x' || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="price-box">
          <h3>Share Statistics</h3>
          <table>
            <tbody>
              <tr>
                <td>Average Vol (3 Months)</td>
                <td>{priceInfo.average_volume || 'N/A'}</td>
              </tr>
              <tr>
                <td>Average Vol (10 Days)</td>
                <td>{priceInfo.average_volume_10days || 'N/A'}</td>
              </tr>
              <tr>
                <td>Shares Outstanding</td>
                <td>{priceInfo.shares_outstanding || 'N/A'}</td>
              </tr>
              <tr>
                <td>Floating Shares</td>
                <td>{priceInfo.floating_shares || 'N/A'}</td>
              </tr>
              <tr>
                <td>Shares Short</td>
                <td>{priceInfo.shares_short || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="chart-container">
        {/* <StockChart symbol={ticker} /> */}
        {/* <PriceChart priceInfo={priceInfo} /> */}
        <TradingViewChart symbol={ticker} />
      </div>
    </div>
  );
}

export default PriceData;
