import React, { useState } from 'react'
import { IconButton } from '@mui/material';
import { Favorite as FavoriteIcon } from '@mui/icons-material';

export const Favorites = ({userEmail, symbol}) => {
    const [favoritesItem, setFavoritesItem] = useState(() => JSON.parse(localStorage.getItem('favorites_' + userEmail)) || []);
    const isFavorite = favoritesItem.includes(symbol)

    const handleToggleFavorite = () => {
        if (!isFavorite) {
            const newFavorite = [...favoritesItem, symbol]
            setFavoritesItem(newFavorite)
            localStorage.setItem('favorites_' + userEmail, JSON.stringify(newFavorite))
        }
        else {
            const newFavorite = favoritesItem.filter((savedSymbol) => savedSymbol !== symbol)
            setFavoritesItem(newFavorite)
            localStorage.setItem('favorites_' + userEmail, JSON.stringify(newFavorite))
        }
    }


    return (
        <>
            <IconButton id="favorite-icon-header" onClick={handleToggleFavorite}>
                <FavoriteIcon  color={isFavorite ? 'primary' : 'inherit'} />
            </IconButton>
        </>
    );
    
}
